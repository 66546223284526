import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PricingCards from '../components/SEOAgency/Pricing'
import Footer from "../components/App/Footer"

const MembershipLevels = () => {
    return (
        <Layout>
            <div className="pt-100"></div>
            <Navbar />
            <PricingCards />
            <div className="project-start-area tasksmanager ptb-25 white-textt">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <h3>Имате Специфична Потреба?</h3>
                            <p className="white-textt"> Доколку имате специфична потреба или ви треба корпоративно решение, слободно контактирајте нè.</p>
                        </div>

                        <div className="col-lg-1 col-md-12 desktop-only"></div>
                        <div className="col-lg-3 col-md-12">
                            <div className="project-start-content">
                                <Link to="/contact" className="default-btn">
                                Контактирајте нè
                                <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="membership-levels-area ptb-100">
                <div className="container">
                    <div className="membership-levels-table table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        {/* <span className="price">Features per user</span> */}
                                        <h5>Карактеристики по корисник</h5>
                                    </th>
                                    <th>
                                        {/* <span className="price">Free</span> */}
                                        <h5>Стартер</h5>
                                    </th>
                                    {/* <th>
                                        <h5>Премиум</h5>
                                    </th> */}
                                    <th>
                                        {/* <span className="price">Business</span> */}
                                        <h5>Стандард</h5>
                                    </th>
                                    <th>
                                        {/* <span className="price">Office</span> */}
                                        <h5>Бизнис</h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                    Корисници
                                    </td>
                                    <td>До 5</td>
                                    {/* <td>Неограничено</td> */}
                                    <td>Неограничено</td>
                                    <td>Неограничено</td>
                                </tr>
                                <tr>
                                    <td>
                                    Месечни задачи
                                    </td>
                                    <td>20</td>
                                    {/* <td>100</td> */}
                                    <td>Неограничено</td>
                                    <td>Неограничено</td>

                                </tr>
                                <tr>
                                    <td>
                                    Складирање на податоци
                                    </td>
                                    <td>200MB</td>
                                    {/* <td>1GB</td> */}
                                    <td>5GB</td>
                                    <td>15GB</td>

                                </tr>
                                <tr>
                                    <td>
                                    Максимална големина на документ
                                    </td>
                                    <td>10MB</td>
                                    {/* <td>50MB</td> */}
                                    <td>100MB</td>
                                    <td>300MB</td>

                                </tr>
                                <tr>
                                    <td>
                                        Tafoma Talk
                                    </td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Видео состаноци
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td>5 состаноци неделно <br/>(45мин по состанок)</td> */}
                                    <td>10 состаноци неделно</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Графици
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Креирање на тимови
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Неделни/месечни извештаи
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Офис пакет
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Вебинар за тим
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>

                                <tr><td></td><td></td><td></td><td></td></tr>                              
                                <tr><td><h5>Наскоро...</h5></td><td></td><td></td><td></td></tr>
                                
                                <tr>
                                    <td>
                                    Шаблони за проекти
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Тафома е-мејл
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Додавање на email адреси
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td> 1</td> */}
                                    <td> До 5</td>
                                    <td> До 10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    )
}

export default MembershipLevels