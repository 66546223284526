import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>Една алатка за сите ваши потреби за соработка</p>

                            <ul className="social-link">
                                <li>
                                    <a href="https://www.facebook.com/tafoma.official/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/tafoma?s=09" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/tafoma_com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/tafoma" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Линкови</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Дома
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/about">
                                        За нас
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/features">
                                        Функционалности
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pricing">
                                        Цени
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Инфо</h3>

                            <ul className="footer-links-list">
                            <li>
                                    <Link to="/blog">
                                        Блог
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        ЧПП
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Контакт
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-sm-6">
                        <StaticImage src={'../../assets/images/security-seal.png'} 
                                        alt="Security Badge." 
                                        className="w-50"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"/>
                    </div> */}

                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    175 5th Ave, New York, NY 10010, <br /> United States
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+44587154756">+1 (123) 456 7890</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:hello@rewy.com">hello@rewy.com</a>
                                </li>
                                <li>
                                    <i className='bx bxs-inbox'></i>
                                    <a href="tel:+557854578964">+55 785 4578964</a>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Tafoma</strong> All rights reserved</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div> */}
        </footer>
    );
}

export default Footer;